import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { getProgress, videoIconStyle, isStartCampaign, isFinishedCampaign, isInprocessCampaign, commentsOffStyle, getProgressColorLike, getProgressColorComment, videoViewsStyle, getProgressColorView, getSource, getEnabledBtnStyle } from '../../utils/PhotoItemHelper';
import { declension } from '../../utils/Utils';
import NotEnoughtCoins from '../tooltips/NotEnoughtCoins';
import close from '../custom_icons/close.svg'
import OrderBase from './OrderBase';
import BtnLoader from '../loaders/BtnLoader';
import SessionErrorOrder from '../tooltips/SessionErrorOrder';

var strings = getLocalizedStrings()
var canvas;
class LanscapeOrder extends OrderBase {
    constructor(props){
        super(props)
        
        
    }
    
    render(){
            const isStories = (this.props.photo.id.includes("_&&&_storie"))

            return (
                    <div className="transformable-profiles landscape_tooltip">
                        <div onClick={this.props.toogleTooltip} className="tooltip_bg"/>
                        <i className="total-tooltip__arrow"></i>
                        <div className="total-tooltip__wrapper">
                            <img onClick={this.props.toogleTooltip} src={close} className="total-tooltip__close"/>
                            <div className="account-total">{strings.ALL_IN_PHOTO}
                                <div className="account-total__container">
                                    {!isStories && <div className="account-total__item">
                                        <h4 className="account-total__heading">{declension(this.props.photo.like_count, strings.LIKE, strings.LIKE_2, strings.LIKE_5)}</h4>
                                        {this.props.photo.like_count >= 0 ? this.props.photo.like_count : "не известно"}
                                    </div>}
                                    {!isStories && <div className="account-total__item">
                                        <h4 className="account-total__heading">{declension(this.props.photo.comment_count, strings.COMMENT, strings.COMMENT_2, strings.COMMENT_5)}</h4>
                                            {this.props.photo.comment_count >= 0 ? this.props.photo.comment_count : "не известно"}
                                    </div>}
                                    <div className="account-total__item">
                                        <h4 className="account-total__heading">{declension(0, strings.REPORT, strings.REPORT_2, strings.REPORT_5)}</h4>
                                        0
                                    </div>
                                </div>

                            <div style={commentsOffStyle(this.props.photo)} className="private-profile">
                                {strings.COMMENTS_OFF}
                            </div>
                            {strings.MADE_ORDERED}
                            <div className="account-total__container">
                                <div className="account-total__item">
                                <h4 className="account-total__heading">{strings.LIKES}</h4>
                                <span style={getProgressColorLike(this.props.photo)}
                                    className="account-total__value_ordered">{getProgress(this.props.photo.our_info_photo)}</span>
                                </div>
                                <div className="account-total__item">
                                <h4 className="account-total__heading">{strings.COMMENTS}</h4>
                                <span style={getProgressColorComment(this.props.photo)}
                                    className="account-total__value_done">{getProgress(this.props.photo.our_info_extra_2)}</span>
                                </div>

                                <div style={videoViewsStyle(this.props.photo)} className="account-total__item">
                                <h4 className="account-total__heading">{strings.VIEWS}</h4>
                                <span style={getProgressColorView(this.props.photo)}
                                    className="account-total__value_done">{getProgress(this.props.photo.our_info_extra_1)}</span>
                                </div>
                            </div>
                            </div>

                            <form className="form-order">
                                <h2 className="h2 form-order__heading">{strings.WANT_MORE}</h2>

                                <label className="form-order__wrapper checkbox-label">
                                    <input onChange={this.likesSwitchChanged} checked={this.state.isLikes} type="checkbox" className="likes_switch" name=""/>
                                    <span>{strings.LIKES}</span>
                                    <input onChange={this.likesValueChanged} type="number" className="form-order__input likes_value" value={this.state.likes}/>
                                </label>
                                <label className="form-order__wrapper checkbox-label">
                                    <input onChange={this.commentsSwitchChanged} checked={this.state.isComments} type="checkbox" className="comments_switch" name=""/>
                                    <span >{strings.COMMENTS_FULL}</span>
                                    <input onChange={this.commentsValueChanged} type="number" value={this.state.comments} className="form-order__input comments_value"/>
                                </label>
                                
                                <label style={videoIconStyle(this.props.photo)} checked={this.state.isViews} className="form-order__wrapper checkbox-label">
                                    <input onChange={this.viewsSwitchChanged}  type="checkbox" className="views_switch" name=""/>
                                    <span >{strings.VIEWS}</span>
                                    <input onChange={this.viewsValueChanged} type="number" value={this.state.views} className="form-order__input views_value"/>
                                </label>
                                <div className="form-order__score">
                                    <span>{strings.TOTAL_PRICE}</span>
                                    <span className="tooltip rate-content__tooltip margin_l_m_0">
                                    <span className="form-order__sum sum_coins">{this.state.sum} {declension(this.state.sum, strings.COIN, strings.COIN_2, strings.COIN_5)}</span>
                                    {this.state.isShowNotEnought && <NotEnoughtCoins arrowStyle={{top : 0, left : 'auto', right : 0}} text={this.state.notEnoughtCoins}/>}
                                    {this.state.isShowSessionError && <SessionErrorOrder arrowStyle={{top : 0, left : 'auto', right : 0}} text={strings.SESSION_DIALOG_TEXT_1}/>}
                                    </span>
                                    
                                </div>
                                {this.state.sum < 20 && <div style={{margin : '16px 0 0 0', fontSize : '14px'}}>{strings.MIN_ORDER_LIKES}</div>}
                                <div onClick={this.createOrder} style={(this.state.isLoading || this.state.sum < 20) ? getEnabledBtnStyle() : {}} className="btn btn_purple form-order__btn create-orderbtn">{strings.CREATE_PROMOTION}
                                    {this.state.isLoading && <BtnLoader />}
                                </div>
                            </form>

                        </div>
                    </div>
            ); 
        
        
    }
}
export default LanscapeOrder;

